import { FC } from 'react';

import { Match, MatchStatus, PlatformID } from '@common/clients/api';
import { CountDownElement } from '@web/atoms/CountDownElement';
import { PlatformToggle } from '@web/molecules/PlatformToggle';

import styles from './ScoreElement.module.scss';

export interface Props {
    match: Match;
    isHorizontal?: boolean; // false by default
    isRectangleBlock?: boolean; // false by default
    hasDarkBackground?: boolean; // false by default;
}

export const ScoreElement: FC<Props> = ({ match, isRectangleBlock, isHorizontal, hasDarkBackground }) => {
    const classes = ['ScoreElement', styles.ScoreElement];

    if (isRectangleBlock) classes.push(styles['is-block']);
    if (isHorizontal) classes.push(styles['is-horizontal']);
    if (hasDarkBackground) classes.push(styles['has-dark-background']);

    switch (match.status) {
        case MatchStatus.PLAYING:
        case MatchStatus.SUSPENDED:
        case MatchStatus.PLAYED:
            if (!hasDarkBackground) classes.push('inverted');

            return (
                <ul className={classes.join(' ')}>
                    <li>{match.homeScore ?? '-'}</li>
                    <li>{match.awayScore ?? '-'}</li>
                </ul>
            );
        default:
            return (
                <>
                    <PlatformToggle include={[PlatformID.VI]}>
                        <ul className={[...classes, 'inverted', 'time'].join(' ')}>
                            <li>
                                <CountDownElement date={match.kickOff} />
                            </li>
                        </ul>
                    </PlatformToggle>
                    <PlatformToggle exclude={[PlatformID.VI]}>
                        <ul className={classes.join(' ')}>
                            <li>-</li>
                            <li>-</li>
                        </ul>
                    </PlatformToggle>
                </>
            );
    }
};
